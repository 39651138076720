<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <NewUsers></NewUsers>
      </v-col>
      <v-col cols="4">
        <NewDemandChart></NewDemandChart>
      </v-col>
      <v-col cols="4">
        <NewPropertiesChart></NewPropertiesChart>
      </v-col>
      <v-col cols="4">
        <NewReservationsChart></NewReservationsChart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewUsers from '../../components/charts/NewUsersChart'
import NewDemandChart from '../../components/charts/NewDemandsChart'
import NewPropertiesChart from '../../components/charts/NewPropertiesChart'
import NewReservationsChart from '../../components/charts/NewReservationsChart'
export default {
  name: 'Dashboard',
  components: { NewReservationsChart, NewPropertiesChart, NewDemandChart, NewUsers }
}
</script>

<style scoped>

</style>
